import {
  RudderstackIntegrations,
  USER_GEO_IP_LOCATION_KEY,
} from 'constants/common'
import { RudderEvent } from 'constants/rudderEvents'
import { getUserLocation } from './common'

type Integrations = {
  [key in RudderstackIntegrations]?: boolean
}

const logEvent = (
  eventName: RudderEvent,
  data = {},
  integrations?: Integrations
): void => {
  const defaultIntegrations = {
    [RudderstackIntegrations.GOOGLE_ADS]: false,
    [RudderstackIntegrations.GOOGLE_TAG_MANAGER]: false,
    [RudderstackIntegrations.FACEBOOK_PIXEL]: false,
    ...(!!integrations && integrations),
  }

  if (globalThis.rudderanalytics) {
    const userLocation = getUserLocation()
    globalThis.rudderanalytics.track(
      eventName,
      data,
      userLocation && {
        integrations: defaultIntegrations,
        location: {
          [USER_GEO_IP_LOCATION_KEY]: userLocation,
        },
      },
      { integrations: defaultIntegrations }
    )
  }

  // Also send all events to Heap https://thriveworks.atlassian.net/browse/THRIV-4731
  if (process.env.NEXT_PUBLIC_HEAP_APP_ID && globalThis.heap) {
    globalThis.heap.track(eventName, data)
  }
}

export default logEvent
