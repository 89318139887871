import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { BreakPoints } from 'constants/common'

type CustomBreakPoint = {
  // minWidth in custom breakpoint would catch screensize minWidth > above
  // Eg :- const {isCustomSize} = useBreakpoint({ minWidth: 2560 })
  // meaning isCustomSize would be true when screenSize is 2560 >=
  minWidth?: number

  // maxWidth in custom breakpoint would catch screensize in between 0 < maxWidth
  // Eg :- const {isCustomSize} = useBreakpoint({ maxWidth: 2560 })
  // meaning isCustomSize would be true when screenSize is between 0 and 2560
  maxWidth?: number
}
// Note:- we can pass both minWidth and maxWidth in the props at the same time as well.
// Eg :- const {isCustomSize} = useBreakpoint({ minWidth: 1200, maxWidth: 2560 })
// it would catch screenSize between 1200 >= && <= 2560

type BreakPointMapping = {
  width: number
  name: string
}

enum BreakPointsValues {
  IS_SMALL_SIZE = 'isSmallSize',
  IS_MEDIUM_SIZE = 'isMediumSize',
  IS_LARGE_SIZE = 'isLargeSize',
  IS_EXTRA_LARGE_SIZE = 'isExtraLargeSize',
  IS_ABOVE_XL_SIZE = 'isAboveXLSize',
  IS_CUSTOM_SIZE = 'isCustomSize',
  IS_MOBILE_SCREEN = 'isMobileScreen',
}

enum V2BreakPointsValues {
  IS_MOBILE_SCREEN = 'isMobileScreen',
  IS_TABLET_SCREEN = 'isTabletScreen',
  IS_DESKTOP_SCREEN = 'isDesktopScreen',
}

const calculateDimensionName = (screenWidth: number): string => {
  const breakpointMappings: BreakPointMapping[] = [
    { width: 576, name: BreakPoints.SM },
    { width: 768, name: BreakPoints.MD },
    { width: 992, name: BreakPoints.LG },
    { width: 1200, name: BreakPoints.XLG },
    { width: 9999, name: BreakPoints.XXL },
  ]

  return breakpointMappings.find(
    (breakPoint: BreakPointMapping) => screenWidth <= breakPoint.width
  ).name
}

const checkWidthInCustomBreakpoint = (
  screenWidth: number,
  customBreakPointArgs: CustomBreakPoint | Required<CustomBreakPoint>[]
): boolean => {
  let isWidthInCustomBreakPoint = false

  //check if customBreakPointArgs exists and not undefined
  if (!!customBreakPointArgs) {
    if (
      'minWidth' in customBreakPointArgs ||
      'maxWidth' in customBreakPointArgs
    ) {
      //customMinWidth and customMaxWidth by default will be 0 and 9999 as they are
      //the minimum and maximum possible values for screen width
      const { minWidth: customMinWidth = 0, maxWidth: customMaxWidth = 9999 } =
        customBreakPointArgs ?? {}
      isWidthInCustomBreakPoint =
        screenWidth >= customMinWidth && screenWidth <= customMaxWidth
    } else if (!!customBreakPointArgs) {
      //check if width lies within any of the custom breakpoint ranges
      isWidthInCustomBreakPoint = (customBreakPointArgs as Required<CustomBreakPoint>[])?.some(
        ({ minWidth: customMinWidth, maxWidth: customMaxWidth }) =>
          screenWidth >= customMinWidth && screenWidth <= customMaxWidth
      )
    }
  }

  return isWidthInCustomBreakPoint
}

const getDeviceConfig = (
  width: number,
  customBreakPointArgs: CustomBreakPoint | Required<CustomBreakPoint>[]
): string => {
  const isWidthInCustomBreakPoint = checkWidthInCustomBreakpoint(
    width,
    customBreakPointArgs
  )

  if (isWidthInCustomBreakPoint) {
    return BreakPoints.CB
  }

  return calculateDimensionName(width)
}

const useBreakpoint = (
  customBreakPointArgs?: CustomBreakPoint | Required<CustomBreakPoint>[]
): Record<BreakPointsValues, boolean> & {
  v2Breakpoints: Record<V2BreakPointsValues, boolean>
} => {
  const [brkPnt, setBrkPnt] = useState<string>(
    getDeviceConfig(document.documentElement.clientWidth, customBreakPointArgs)
  )

  useEffect(() => {
    const updateScreenWidth = function () {
      setBrkPnt(
        getDeviceConfig(
          document.documentElement.clientWidth,
          customBreakPointArgs
        )
      )
    }

    updateScreenWidth()
    const calcScreenWidth = throttle(updateScreenWidth, 50)
    window.addEventListener('resize', calcScreenWidth)
    return () => window.removeEventListener('resize', calcScreenWidth)
  }, [])

  const v2Breakpoints: Record<V2BreakPointsValues, boolean> = {
    [V2BreakPointsValues.IS_MOBILE_SCREEN]: brkPnt === BreakPoints.SM,
    [V2BreakPointsValues.IS_TABLET_SCREEN]:
      brkPnt === BreakPoints.MD || brkPnt === BreakPoints.LG,
    [V2BreakPointsValues.IS_DESKTOP_SCREEN]:
      !(brkPnt === BreakPoints.MD || brkPnt === BreakPoints.LG) &&
      !(brkPnt === BreakPoints.SM),
  }

  return {
    [BreakPointsValues.IS_SMALL_SIZE]: brkPnt === BreakPoints.SM,
    [BreakPointsValues.IS_MEDIUM_SIZE]: brkPnt === BreakPoints.MD,
    [BreakPointsValues.IS_LARGE_SIZE]: brkPnt === BreakPoints.LG,
    [BreakPointsValues.IS_EXTRA_LARGE_SIZE]: brkPnt === BreakPoints.XLG,
    [BreakPointsValues.IS_ABOVE_XL_SIZE]: brkPnt === BreakPoints.XXL,
    [BreakPointsValues.IS_CUSTOM_SIZE]: brkPnt === BreakPoints.CB,
    [BreakPointsValues.IS_MOBILE_SCREEN]:
      brkPnt === BreakPoints.SM || brkPnt === BreakPoints.MD,
    v2Breakpoints,
  }
}

export default useBreakpoint
