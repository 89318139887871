export enum RudderEvent {
  ACCOUNT_CREATION_FAILED = 'Account Creation Failed',
  AVAILABLE_PROVIDERS_SHOWN = 'Available Providers Shown',
  BASIC_INFORMATION_GIVEN = 'Basic Information Given',
  BILLING_DETAILS_DATE_CHANGED = 'Billing Details date changed',
  BILLING_DETAILS_PAGE_VIEWED = 'Billing page viewed',
  BOOKING_CONSENT_GIVEN = 'Booking Consent Given',
  BOOKING_COMPLETED = 'Booking Completed',
  BOOKING_CONFIRMED = 'Booking Confirmed',
  CLIENT_LIST_PAGE_VIEWED = 'Client List Page Viewed',
  CLIENT_PAGE_COMMUNICATION_HISTORY_VIEWED = 'Client Page Communication History Viewed',
  CLIENT_PAGE_CONSENT_VIEWED = 'Client Page Consent Viewed',
  CLIENT_PAGE_INSURANCE_DETAILS_VIEWED = 'Client Page Insurance Details Viewed',
  CLIENT_PAGE_SESSIONS_VIEWED = 'Client Page Sessions Viewed',
  CLIENT_PAGE_SUPPORT_BILLING_VIEWED = 'Client Page Support/Billing Viewed',
  CLOSED_FIRESIDE_COURSE = 'Pressed Back on Fireside Course Detail Page',
  FIRESIDE_COURSE_DESCRIPTION_CLICK = 'Fireside Course Description Clicked',
  INSURANCE_VALIDATION_RECEIVED = 'Insurance Validation Received',
  INSURANCE_VALIDATION_RECEIVED_ERROR = 'Insurance Validation Received Error',
  INSURANCE_VALIDATION_REQUESTED = 'Insurance Validation Requested',
  INSURANCE_VALIDATION_SHOWN = 'Insurance Validation Shown',
  INSURANCE_USER_DECISION = 'Insurance User Decision',
  BOOKING_PAYMENT_METHOD_CHANGED = 'Booking Payment Method Changed',
  LOGIN_CTA_CLICKED = 'Signup/Login CTA clicked',
  MAGIC_LINK_RESENT = 'Magic link resent',
  OPENED_FIRESIDE = 'Fireside Click',
  PAY_NOW_CLICK = 'Pay now clicked',
  PAYMENT_RECEIPT_PDF_DOWNLOAD = 'Client Dashboard Payment Receipt Downloaded',
  PROVIDER_MY_SCHEDULE_VIEWED = 'Provider My Schedule Viewed',
  PROVIDER_PAGE_LINK_CLICKED = 'Provider Profile Page Navigation Selected',
  PROVIDER_PAGE_VIDEO_PLAYED = 'Provider Profile Page Video Played',
  PROVIDER_SLOT_SELECTED = 'Provider Slot Selected',
  PROVIDER_ZOOM_LAUNCHED = 'Provider Zoom Launched',
  PROVIDERS_PROFILE_PAGE_VIEWED = 'Providers Profile Page Viewed',
  PROVIDER_BILLING_NOTES_VIEW = 'Provider Billing/Support Notes Viewed',
  PROVIDER_DAILY_SCHEDULE_CONSENT_VIEW = 'Provider Client Consent Viewed from Daily Schedule',
  PROVIDER_SESSION_HISTORY_CONSENT_VIEW = 'Provider Client Consent Viewed from Client Session History',
  PROVIDER_CLIENT_SESSION_VIEW = 'Provider Client Sessions Viewed',
  REBOOKING_BUTTON_CLICK = 'Book Again Clicked',
  REBOOKING_SLOT_SELECTED = 'Rebooking Slot Selected',
  REMOVE_THIS_CLIENT_CLICKED = 'Remove This Client Clicked',
  REMOVE_THIS_CLIENT_CONFIRMATION = 'Remove This Client Confirmation',
  RP_NOT_CREATED = 'Responsible party not created',
  SEEK_FIRESIDE_LESSON_VIDEO = 'Fireside Chapter Video Seeked',
  SELECTED_FIRESIDE_COURSE = 'Fireside Course View',
  SLOTS_SHOWN_FOR_PROVIDER_REBOOKING = 'Slots Shown for Rebooking Provider',
  SE_FRIENDLY_PROVIDER_LISTING = 'Local Provider Listing Shown',
  SESSION_CANCELLED = 'Session Cancelled',
  PROVIDER_PROFILE_PAGE_AVAILABILITY_SHOWN = 'Provider Profile Page Availability Shown',
  VIEW_FIRESIDE_LESSON_VIDEO = 'Fireside Chapter Video View',
  NEWSLETTER_EMAIL_SUBMITTED = 'Newsletter Email Submitted',
  INSURANCE_VERIFICATION_HISTORY_VIEWED = 'Insurance Verification History Viewed',
  INSURANCE_VERIFICATION_HISTORY_LINE_ITEM_VIEWED = 'Insurance Verification History Line Item Viewed',
  PVERIFY_VERFIFICATION_VIEWED = 'pVerify Verification Viewed',
  PATIENT_EDITED_BY_SUPPORT_BEFORE_FIRST_APPOINTMENT = 'Patient Edited By Support Before First Appointment',
  SE_FRIENDLY_SEARCH_CLICKED = 'SE Friendly Search Clicked',
  SE_FRIENDLY_SEARCH_OPTION_SELECTED = 'SE Friendly Search Option Selected',
  BOOKING_CONSENT_MODAL_VIEWED = 'Booking Consent Modal Viewed',
  PHONE_NUMBER_CLICKED = 'Phone Number Clicked',
  BOOKING_LOGIN_MODAL_SHOWN = 'Booking Login Modal Shown',
  BOOKING_LOGIN_MODAL_EMAIL_SUBMITTED = 'Booking Login Modal Email Submitted',
  BOOKING_LOGIN_MODAL_LOGGED_IN = 'Booking Login Modal Logged In',
  BOOKING_FAILED = 'Booking Failed',
  BOOKING_WARNED = 'Booking Warned',
  BOOKING_CTA_CLICKED = 'Booking CTA Clicked',
  PATIENT_CARE_PAUSED = 'Patient Care Paused',
  BOOKING_RESCHEDULED = 'Booking Rescheduled',
  BOOKING_NEXT_OPENING_CLICKED = 'Booking Next Opening Clicked',
  BOOKING_MODAL_ALL_AVAILABILITY_SHOWN = 'Booking Modal All Availability Shown',
  BOOKING_TOOL_SHOW_MAP_CLICKED = 'Booking Tool Show Map Clicked',
  BOOKING_TOOL_HIDE_MAP_CLICKED = 'Booking Tool Hide Map Clicked',
  MEDICAID_USER_RETURNED = 'Medicaid User Returned',
  SHOW_PARTIAL_MATCHES_CLICKED = 'Booking Show Partial Matches Clicked',
  BOOKING_MODAL_OUT_OF_NETWORK_SHOWN = 'Booking Modal Out Of Network Shown',
  BOOKING_MODAL_OUT_OF_NETWORK_INTERACTION = 'Booking Modal Out Of Network Interaction',
  LOGIN_EMAIL_SUBMITTED = 'Login Email Submitted',
  SUCESSFULLY_LOGGED_IN = 'Sucessfully Logged In',
  SIMILAR_PROVIDER_SHOWN = 'Similar Providers Shown',
  SEE_AVAILABLE_PROVIDER_CLICKED = 'See Available Providers Clicked',
}
