import Script from 'next/script'
import { loginWithGoogleSSO } from 'dataSource/auth'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import throttle from 'lodash/throttle'
import {
  GOOGLE_SIGN_IN_BUTTON_ID,
  GOOGLE_SIGN_IN_CLIENT_ID,
  RELAY_STATE_TYPE,
} from '../constants'
import qs from 'qs'
import { pageRoutes } from 'constants/routing'

const GoogleSignInButton: React.FC = () => {
  const router = useRouter()
  const { query } = router
  const { returnTo = '' } = query ?? {}

  const handleGoogleSignInScriptLoad = () => {
    // Initialize the Google Sign-In API
    if (!!window.google?.accounts) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_SIGN_IN_CLIENT_ID,
        callback: handleCredentialResponse,
        popupMode: false, // Open the Google Sign-In dialog in the same window
        promptParentFallback: document.body, // Show the prompt in the same tab
      })
      renderGoogleSignInButton()
      const resizeSignInButton = throttle(renderGoogleSignInButton, 50)
      window.addEventListener('resize', resizeSignInButton)
    }
  }

  /*
    Render Google sign in button responsively.
    - Sign in button width is 400px for all screen sizes above 448px screen width
    - For screen sizes below 448px, the button width will be (total screen width - 48px)
      as we add horizontal padding of 24px on both left and right side of the button
  */
  const renderGoogleSignInButton = () => {
    const customStyles = {
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      shape: 'pill',
    }
    const loginContainerWidth =
      document.querySelector(`#${GOOGLE_SIGN_IN_BUTTON_ID}`)?.parentElement
        .clientWidth - 48
    window.google.accounts.id.renderButton(
      document.getElementById(GOOGLE_SIGN_IN_BUTTON_ID),
      {
        ...customStyles,
        width:
          window.screen.availWidth >= 448 ? '400' : `${loginContainerWidth}`,
      }
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCredentialResponse = async (response: any) => {
    try {
      if (response.credential) {
        const currentProtocolAndHost = () => {
          const protocol = window.location.protocol
          const host = window.location.host
          return `${protocol}//${host}`
        }

        const [returnToPath, returnToQuery] = (returnTo as string).split('?')
        const isReturnToBookingFlow = !!returnToPath.includes(
          pageRoutes.BOOKING
        )
        const redirectQuery = qs.parse(returnToQuery) ?? {}
        const clientSSOLoginQuery = qs.stringify(
          {
            ...redirectQuery,
            ...(isReturnToBookingFlow && { googleSSOLogin: true }),
          },
          { addQueryPrefix: true }
        )

        const relayState = {
          type: RELAY_STATE_TYPE,
          redirectTo: `${returnToPath}${clientSSOLoginQuery}`,
          baseUrl: currentProtocolAndHost(),
        }

        let redirectUrl = await loginWithGoogleSSO(
          response.credential,
          relayState
        )

        if (!isReturnToBookingFlow) {
          redirectUrl = `${redirectUrl}?${qs.stringify({
            googleSSOLogin: true,
          })}`
        }

        window.location.replace(redirectUrl)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in Google Sign-In:', Error)
    }
  }

  useEffect(() => {
    /*
      TODO: Added a temporary work around re-rendering of the google sso button to show the button
      after back and forth route changes. Need to investigate a way to reload the GSI script
      when nextJS route changes happen.
    */
    if (!!window.google?.accounts) {
      renderGoogleSignInButton()
    }

    return () => {
      if (!!window.google?.accounts) {
        window.removeEventListener('resize', renderGoogleSignInButton)
      }
    }
  }, [])

  return (
    <>
      <Script
        src="https://accounts.google.com/gsi/client"
        async
        defer
        onLoad={handleGoogleSignInScriptLoad}
      />
      <div id={GOOGLE_SIGN_IN_BUTTON_ID} />
    </>
  )
}

export default GoogleSignInButton
