import { UPDATE_THRIV_AUTH, UPDATE_VERIFY_DOB } from './constants'

export const updateEmailAction = (
  payload: ThrivAuthState
): ReducerActions<ThrivAuthState> => {
  return {
    type: UPDATE_THRIV_AUTH,
    payload,
  }
}

export const setVerifyDOB = (payload: boolean): ReducerActions<boolean> => {
  return {
    type: UPDATE_VERIFY_DOB,
    payload,
  }
}
