import Image from 'next/legacy/image'

type BrandLogoProps = {
  width: number
  height: number
  className?: string
}

const BrandLogo: React.FC<BrandLogoProps> = ({
  width,
  height,
  className = '',
}) => {
  const brandLogoSrc = '/images/Sunbeam.svg'
  const brandLogoAlt = 'Thriveworks Logo'
  return (
    <Image
      unoptimized
      src={brandLogoSrc}
      alt={brandLogoAlt}
      width={width}
      height={height}
      className={className}
    />
  )
}

export default BrandLogo
