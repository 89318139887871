import BSForm from 'react-bootstrap/Form'
import { FormControlProps } from 'react-bootstrap/FormControl'
import classnames from 'classnames'
import get from 'lodash/get'
import { FieldProps } from 'formik'
import Icon from 'common/Icon'
import styles from './index.module.scss'

/**
 * @param {boolean} explicitlyShowError show error even if data is prefilled
 * @param {object} field Contains form props like name, value, onChange
 * @param {object} form values, set handlers, get handlers, dirty, isValid, status, etc
 */

type TextInputProps = {
  itemSelected?: boolean
  prependIcon?: () => JSX.Element
  appendIcon?: () => JSX.Element
  onBlur: (e: React.BaseSyntheticEvent) => void
  label?: string
  labelClass?: string
  containerClassName?: string
  inputContainerClassName?: string
  inputClass?: string
  inputErrorClassName?: string
  autoComplete?: string
  hint?: string
  subTextClass?: string
  explicitlyShowError?: boolean
  showNewsletterErrorComponent?: boolean
  errorMessageIcon?: string
  backgroundIcon?: { url: string; style?: React.CSSProperties }
}

const TextInput: React.FC<FieldProps & FormControlProps & TextInputProps> = (
  props
) => {
  const {
    field,
    form: { touched, errors, setFieldTouched },
    className = '',
    containerClassName = 'mt-4',
    inputContainerClassName = 'd-flex align-items-center justify-content-between mb-sm-4 mb-md-1',
    inputClass,
    inputErrorClassName,
    hint = '',
    prependIcon,
    appendIcon,
    onBlur,
    label = '',
    labelClass = 'fs-14',
    autoComplete = 'none',
    subTextClass = '',
    explicitlyShowError = false,
    showNewsletterErrorComponent = false,
    errorMessageIcon,
    backgroundIcon,
    ...rest
  } = props
  const isTouched = get(touched, field.name)
  const fieldError = get(errors, field.name)
  const showError = (isTouched || explicitlyShowError) && !!fieldError

  const styleControl = backgroundIcon
    ? {
        backgroundImage: `url(${backgroundIcon.url})`,
        backgroundPosition: '10px center',
        backgroundRepeat: 'no-repeat',
        paddingLeft: '35px',
        ...backgroundIcon.style,
      }
    : {}

  const NewsletterErrorDisplay = () => {
    return (
      <div
        className={`p-3 card shadow-none ${styles.newsletter_error_container}`}
      >
        <div className={styles.icon_container}>
          <Icon icon="exclamationPoint" className={styles.error_icon} />
        </div>
        {field.value?.length < 1 ? (
          <p className={styles.error_message}>
            Oops, this is a mandatory field! Please enter your email.
          </p>
        ) : (
          <p className={styles.error_message}>
            The email address entered is invalid, please check the formatting
            (e.g. email@domain.com).
          </p>
        )}
      </div>
    )
  }

  return (
    <div className={containerClassName}>
      <div className={`${inputContainerClassName} ${subTextClass}`}>
        {!!label && <div className={labelClass}>{label}</div>}
        {!!hint && <div className="microcopy-text">{hint}</div>}
      </div>
      <BSForm.Group onBlur={onBlur} className="d-flex mb-0 position-relative">
        {!!prependIcon && prependIcon()}
        <BSForm.Control
          style={styleControl}
          autoComplete={autoComplete}
          type="text"
          {...field}
          {...rest}
          onBlur={() => {
            if (!!field.value) {
              setFieldTouched(field.name)
            }
          }}
          size="lg"
          className={classnames(`${className} ${inputClass}`, {
            'form-control-filled': field?.value,
            'form-control-error': showError && !showNewsletterErrorComponent,
            [styles.newsletterForm_control_error]:
              showError && showNewsletterErrorComponent,
          })}
          aria-label={label}
        />
        {!!appendIcon && appendIcon()}
      </BSForm.Group>
      {showError ? (
        showNewsletterErrorComponent ? (
          <NewsletterErrorDisplay />
        ) : (
          <div className={classnames(inputErrorClassName, 'input-error')}>
            {errorMessageIcon}
            {fieldError}
          </div>
        )
      ) : null}
    </div>
  )
}

export default TextInput
