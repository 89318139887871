import { Field, Form, FormikProvider, useFormik } from 'formik'
import styles from './index.module.scss'
import classNames from 'classnames'
import TextInput from 'common/FormInputs/TextInput'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useRouter } from 'next/router'
import useThriveAuth from 'hooks/useThriveAuth'
import { getTreatment } from 'utils/treatment'
import { TreatmentFlags, treatmentFlagValues } from 'constants/treatments'
import React from 'react'
import { toast } from 'react-toastify'
import ErrorBanner from 'common/Banners/ErrorBanner'
import { emailSchema } from 'utils/commonYupSchemas'
import { useDispatch } from 'react-redux'
import { updateEmailAction } from 'state/ThrivAuth/actions'
import GoogleSignInButton from 'app/Auth/GoogleSignInButton'
import { LoginMethod, RELAY_STATE_TYPE } from 'app/Auth/constants'
import logEvent from 'utils/logEvent'
import { RudderEvent } from 'constants/rudderEvents'

type LoginFormProps = {
  onLoginFormSubmit: (isSupportUser?: boolean) => void
  showLoginFormHeading?: boolean
  isEmailOTPFlowON?: boolean
}

const LoginForm: React.FC<LoginFormProps> = ({
  showLoginFormHeading = false,
  onLoginFormSubmit,
  isEmailOTPFlowON = false,
}) => {
  const router = useRouter()
  const { query, route } = router
  const { requestMagicLink, requestOTP } = useThriveAuth()
  const dispatch = useDispatch()

  const SamlEnabled = () => {
    if (process.env.NEXT_PUBLIC_SAML_ENABLED === 'true') {
      return true
    } else if (
      process.env.NODE_ENV === 'development' ||
      process.env.NEXT_PUBLIC_SAML_ENABLED === 'false'
    ) {
      return false
    }

    const treatment = getTreatment(TreatmentFlags.WEB_SAML_ENABLED)
    return treatment === treatmentFlagValues.ENABLE
  }

  const currentProtocolAndHost = () => {
    const protocol = window.location.protocol
    const host = window.location.host
    return `${protocol}//${host}`
  }

  const navigateToSamlLogin = () => {
    const RelayState = {
      type: RELAY_STATE_TYPE,
      redirectTo: query.returnTo as string,
      baseUrl: currentProtocolAndHost(),
    }
    const RelayStateDTO = btoa(JSON.stringify(RelayState))
    window.location.href = `${process.env.NEXT_PUBLIC_SERVER_URL}/auth/login/saml?RelayState=${RelayStateDTO}`
  }

  const deliverMagicLink = async (params) => {
    await requestMagicLink(values.email, params)
  }

  const isOnLoginPage = route.includes('/login')

  const loginFormSubmit = async (values, actions) => {
    let skipFinal = false

    const params = {
      redirectTo: query.returnTo as string,
    }

    const isSupportUser = values.email.toLowerCase().endsWith('thriveworks.com')

    try {
      // 'Login Email Submitted' event should only be called on login page - outside booking funnel pages
      if (isOnLoginPage) {
        logEvent(RudderEvent.LOGIN_EMAIL_SUBMITTED, {
          login_method:
            isEmailOTPFlowON && !isSupportUser
              ? LoginMethod.EMAIL_OTP
              : LoginMethod.EMAIL,
        })
      }

      if (SamlEnabled() && isSupportUser) {
        skipFinal = true
        navigateToSamlLogin()
      } else if (isEmailOTPFlowON && !isSupportUser) {
        await requestOTP(values.email)
      } else {
        deliverMagicLink(params)
      }
    } catch (error) {
      toast(
        <ErrorBanner
          message={error?.message ?? 'Something went wrong! Please try again.'}
          isRedesign
        />,
        {
          className: styles.error_banner,
        }
      )
    } finally {
      if (!skipFinal) {
        actions.setSubmitting(false)
        dispatch(updateEmailAction({ email: values.email, ...params }))
        onLoginFormSubmit(isSupportUser)
      }
    }
  }

  const formikMagicLinkLoginContext = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: emailSchema,
    onSubmit: loginFormSubmit,
  })

  const {
    isValid,
    values,
    errors,
    touched,
    isSubmitting,
  } = formikMagicLinkLoginContext

  const isSupportUser = values.email.toLowerCase().endsWith('thriveworks.com')

  return (
    <>
      <GoogleSignInButton />
      <div className={styles.sign_in_divider_container}>
        <div className={styles.sign_in_divider} />
        <div className={styles.sign_in_divider_content}>OR</div>
        <div className={styles.sign_in_divider} />
      </div>
      {showLoginFormHeading && (
        <span className={styles.login_form_heading}>Verify your email</span>
      )}
      <FormikProvider value={formikMagicLinkLoginContext}>
        <Form className={styles.form_container}>
          <div className={styles.email_input_container}>
            <Field
              placeholder="Enter your email address"
              type="email"
              name="email"
              className={classNames(styles.email_field, {
                [styles.email_field_error]: errors.email && touched.email,
              })}
              containerClassName="mt-0"
              component={TextInput}
              inputContainerClassName=""
              inputClass={styles.new_input_field}
              inputErrorClassName={styles.error_text}
            />
          </div>
          <Button
            disabled={!isValid || !values?.email || isSubmitting}
            type="submit"
            className={styles.login_button}
          >
            {isSubmitting ? (
              <Spinner animation="border" />
            ) : isEmailOTPFlowON && !isSupportUser ? (
              <div>Send me a code</div>
            ) : (
              <div>Send verification link</div>
            )}
          </Button>
        </Form>
      </FormikProvider>
    </>
  )
}

export default LoginForm
