export const RELAY_STATE_TYPE = 'front-end-app'

export const GOOGLE_SIGN_IN_BUTTON_ID = 'googleButtonDiv'

/*
  Google sign in client id is not a secret. Also, it will not change based on the
  environment. So, hard coded the id as a constant instead of adding in the env variables
*/
export const GOOGLE_SIGN_IN_CLIENT_ID =
  '782108479616-louemh04778drtp7pg5172bhebb06if5.apps.googleusercontent.com'

export enum LoginMethod {
  EMAIL = 'email',
  EMAIL_OTP = 'email OTP',
  GOOGLE_SSO = 'GOOGLE_SSO',
}
