import LoginForm from 'app/Auth/LoginForm'
import classNames from 'classnames'
import BrandLogo from 'common/BrandLogo'
import HeadComponent from 'common/HeadComponent'
import { pageRoutes } from 'constants/routing'
import { TreatmentFlags, treatmentFlagValues } from 'constants/treatments'
import useGetTreatment from 'hooks/treatments/useGetTreatment'
import useBreakpoint from 'hooks/useBreakPoint'
import useThriveAuth from 'hooks/useThriveAuth'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { choosePermissionBasedUserDestination } from '../../utils/common'
import styles from './index.module.scss'

type LoginPageProps = {
  canonicalLink: string
}

export const Login: React.FC<LoginPageProps> = ({ canonicalLink }) => {
  const router = useRouter()
  const { query } = router
  const {
    isLoading,
    isAuthenticated,
    permissions,
    isSupportUser,
  } = useThriveAuth()

  const isRedirecting = !isLoading && isAuthenticated

  const emailOTPFlowTreatment = useGetTreatment(
    TreatmentFlags.EMAIL_OTP_LOGIN,
    true,
    {
      support: isSupportUser,
    }
  )

  const isEmailOTPFlowON =
    emailOTPFlowTreatment === treatmentFlagValues.V1_EMAIL_OTP_LOGIN

  const onLoginFormSubmit = (isSupportUser): void => {
    isEmailOTPFlowON && !isSupportUser
      ? router.push(pageRoutes.LOGIN_VERIFY_OTP)
      : router.push(pageRoutes.LOGIN_EMAIL_SENT)
  }

  useEffect(() => {
    if (isRedirecting) {
      const redirectUrl = choosePermissionBasedUserDestination(
        permissions,
        query.returnTo as string
      )
      router.replace(redirectUrl)
    }
  }, [isRedirecting, permissions, query])

  const { isMobileScreen } = useBreakpoint()

  if (isRedirecting) {
    return (
      <div className="h-100 flex-center">
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <HeadComponent
      title="Login | Thriveworks"
      ogTitleList={['Login Page']}
      canonicalLink={canonicalLink}
    >
      <main className={styles.login_page_main_container}>
        <div className={styles.login_page_top_container}>
          <div
            className={classNames(
              styles.login_container,
              'h-100 d-flex flex-center flex-column'
            )}
          >
            <div
              className={classNames('text-center', styles.brand_logo_container)}
            >
              <a href="/">
                <BrandLogo width={275} height={50} />
              </a>
            </div>
            <div
              className={classNames('text-center', styles.login_span_container)}
            >
              <span className={classNames(styles.login_span, 'fs-32')}>
                Log in / Sign up
              </span>

              {query?.show_booking_subheader && (
                <div className={classNames('fs-16', styles.booking_subheader)}>
                  Then we{`'`}ll finish booking your session.
                </div>
              )}
            </div>

            <div className={styles.login_form_container}>
              <LoginForm
                onLoginFormSubmit={onLoginFormSubmit}
                isEmailOTPFlowON={isEmailOTPFlowON}
              />
              <div
                className={classNames(
                  styles.agreement_microcopy,
                  'microcopy-text'
                )}
              >
                By creating an account, you agree to the Thriveworks{`' `}
                <a
                  href="privacy-policy-terms-use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Terms and Privacy Policy.</u>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.login_footer_container}>
          <div className={styles.image_sun}>
            <img
              src="/images/footer-top-bg.svg"
              width="100%"
              height="100%"
              alt="Sun Illustration"
            />
          </div>
          <img
            src="/images/footer-top-left.svg"
            srcSet="/images/footer-top-left.webp"
            height="120%"
            width="25%"
            className={styles.image_trees_left}
            alt="Trees Illustration"
          />
          <img
            src="/images/footer-top-right.svg"
            srcSet="/images/footer-top-right.webp"
            height="120%"
            width="25%"
            className={styles.image_trees_right}
            alt="Trees Illustration"
          />
          {isMobileScreen ? <div className={styles.extra_footer_height} /> : ''}
        </div>
      </main>
    </HeadComponent>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}: GetServerSidePropsContext) => {
  const { token, redirectTo } = query || {}

  const baseUrl = `https://${req.headers.host}`

  if (token) {
    return {
      redirect: {
        destination: redirectTo
          ? `/login/${token}?redirectTo=${redirectTo}`
          : `/login/${token}`,
        permanent: false,
      },
    }
  }

  const canonicalLink = `${baseUrl}/login?wp=1`

  return {
    props: { canonicalLink },
  }
}

export default Login
