import Head from 'next/head'

type HeadProps = {
  title?: string
  ogTitleList?: string[]
  content?: string
  ldJsonSchema?: LdJsonSchema
  canonicalLink?: string
}

const HeadComponent: React.FC<HeadProps> = ({
  title,
  ogTitleList,
  content,
  canonicalLink,
  ldJsonSchema,
  children,
}) => {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

  const metaTags = ogTitleList
    ? ogTitleList.map((og) => (
        <meta property="og:title" content={og} key={og} />
      ))
    : null

  return (
    <>
      <Head>
        {metaTags && metaTags}
        {title && <title>{title}</title>}
        {content && <meta name="description" content={content} />}
        {!isProduction && <meta name="robots" content="noindex, nofollow" />}
        {canonicalLink && (
          <link rel="canonical" href={canonicalLink.toLowerCase()} />
        )}
        {ldJsonSchema &&
          // add multiple ldJsonSchemas
          (Array.isArray(ldJsonSchema) ? (
            ldJsonSchema.map((schema, index) => (
              <script
                key={index}
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
              />
            ))
          ) : (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonSchema) }}
            />
          ))}
      </Head>
      {children}
    </>
  )
}

export default HeadComponent
